<template>
  <div class="category-list-common">
    <!-- header category -->
    <div class="header-category">
      <div class="has-bg">
        <!-- sidebar on sp -->
        <button @click="showSearchModal"
                v-if="!checkWebview()"
                class="show-sidebar">プログラムをさがす<img src="../../assets/images/icon/arrow-down-black.png" alt="icon"/>
        </button>

        <!-- title category -->
        <div class="title-category">
          <p>キーワード: {{ this.$route.query.keyword }}</p><span class="total-cate pc">{{ pagination.total }}件</span>
        </div>
      </div>

      <!-- sort category -->
      <div class="sort-category">
        <!-- label total category on sp -->
        <span class="total-cate">{{ pagination.total }}件</span>

        <div class="wrap-select">
          <select v-model="selectSort"
                  @change="sortPage">
            <option value="" disabled>選択してください</option>
            <option value="0" class="select-dropdown__list-item">新着順</option>
            <option value="1" class="select-dropdown__list-item">ポイントが高い順</option>
            <option value="2" class="select-dropdown__list-item">締め切りが近い順</option>
          </select>
          <img src="../../assets/images/icon/arrow-down.png" alt="">
        </div>
      </div>
    </div>

    <!-- list category -->
    <ListCategory :list="searchList"
                  v-if="searchList.length > 0"/>

    <!-- NOTE DATA EMPTY -->
    <p class="data-empty" v-else-if="rendered">該当するデータが見つかりませんでした。</p>

    <!-- pagination -->
    <Pagination class="pagination"
                v-if="rendered"
                :total-page="pagination.totalPages"
                @change-page = changePage
                :current-page="pageActive" />
  </div>
</template>

<script>
import ListCategory from '@/components/ListCategory'
import Pagination from '@/components/Pagination'
import { mapActions, mapState } from 'vuex'
import Common from '@/mixins/common.mixin'
import store from '@/store'

export default {
  name: 'SearchResult',

  components: { Pagination, ListCategory },

  mixins: [Common],

  watch: {
    '$route.params': {
      handler () {
        this.params = {
          ...this.params,
          keyword: this.$route.query.keyword
        }
        store.dispatch('search/getListSearch', this.params)
      }
    }
  },

  mounted () {
    // reset sort when change param search
    this.selectSort = ''

    // hide app's loading after page render
    if (Common.methods.checkWebview()) {
      Common.methods.triggerEventApp('PAGE_RENDERED', {})
    }
  },

  computed: {
    ...mapState('search', ['searchList', 'pagination']),
    ...mapState('category', ['pageActive'])
  },

  data () {
    return {
      params: {
        perPage: 20,
        page: 1,
        // is_opening: 1,
        orderBy: 'publish_start_datetime',
        sortedBy: 'desc'
      },
      selectSort: '',
      sortParams: [
        {
          perPage: 20,
          page: 1,
          // is_opening: 1,
          orderBy: 'created_at',
          sortedBy: 'desc'
        },
        {
          perPage: 20,
          page: 1,
          // is_opening: 1,
          order_option: 'point'
        },
        {
          perPage: 20,
          page: 1,
          // is_opening: 1,
          orderBy: 'publish_end_datetime',
          sortedBy: 'asc'
        }
      ],
      rendered: false
    }
  },

  methods: {
    ...mapActions('search', ['getListSearch']),

    showSearchModal () {
      this.$store.commit('user/DISPLAY_MODAL', true)
    },

    changePage (page) {
      this.params = {
        ...this.params,
        page: page
      }

      this.$store.commit('category/SET_PAGE_ACTIVE', page)
      this.fetchList(this.params)
    },

    sortPage () {
      this.sortParams[this.selectSort] = {
        ...this.sortParams[this.selectSort],
        page: this.pageActive
      }
      this.params = {
        ...this.sortParams[this.selectSort],
        keyword: this.$route.query.keyword
      }
      this.fetchList(this.params)
    },

    // FETCH LIST
    fetchList (params) {
      this.getListSearch(params)

      // scroll to top when change page
      window.scrollTo({ top: 0, behavior: 'smooth' })
    },

    // change flag rendered to show text empty if data empty
    pageRender () {
      this.rendered = true
    }
  }
}
</script>

<style scoped>
.data-empty {
  font-weight: bold;
  margin-bottom: 50px;
}
</style>
